import palette from '../palette';

export default {
    alertTitle: {
        color: palette.mindShaft,
    },

    alertIcon: {
        '& svg': {
            fill: palette.mindShaft,
            opacity: 0.6,
        }
    },
    
    info: {
        backgroundColor: palette.shipCove,
        borderColor: palette.toryBlue,
    },

    negative: {
        backgroundColor: palette.shipCove,
        borderColor: palette.blue,
    },

    positive: {
        backgroundColor: palette.swampGreen,
        borderColor: palette.apple,
    },

    warning: {
        backgroundColor: palette.pink,
        borderColor: palette.sangria,
    },

    wrap: {
        color: palette.mindShaft,
        fontFamily: 'Arial, Helvetica, sans-serif',

        'h3': {
            color: palette.mindShaft,
        },
    }
  };