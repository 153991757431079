import palette from '../palette';

export default {
    colorbar: {
        background: palette.blueLagoon,
        height: 5
    },

    headerTitleFontWeight: 'normal',

    pageHeaderTitle: {
        color: palette.white
    }, 

    headerTitleDark: {
        background: palette.toryBlue,
        color: palette.white
    },

    headerTitleSmb: {
        background: palette.toryBlue,
        color: palette.white
    },

    headerTotalsSmb: {
        background: palette.midGray,
        color: palette.white
    },

    headerTotalsDark: {
        background: palette.midGray,
        color: palette.white
    },

    backNavBackground: {
        background: palette.midGray,
    },

    backNavBackgroundDark: {
        background: palette.midGray,
    },

    backNavIconColorDark: palette.treePoppy,

    backNavSmbIconColor: palette.treePoppy,

    summaryFooter: {
        backgroundColor: palette.midGray,
        color: palette.white
    },

    footerButtonPrimary: {
        background: palette.apple,
        borderColor: palette.apple,
        borderRadius: 4,
        color: palette.white,
        '&:hover, &:focus': {
            background: palette.swampGreen,
            borderColor: palette.swampGreen,
            color: palette.mindShaft
        },
        '&.disabled': {
            color: palette.white,
            background: palette.horizon
        }
    },

    footerButtonSecondary: {
        background: palette.white,
        borderColor: palette.white,
        color: palette.mindShaft,
        '&:hover, &:focus': {
            background: palette.silverChalice,
            borderColor: palette.silverChalice,
            color: palette.mindShaft
        },
        '&.disabled': {
            background: palette.white,
            color: palette.horizon
        }
    }
};