import palette from '../palette';

export default {
  summaryFooter: {
    backgroundColor: palette.midGray,
    color: palette.white
  },
 
  footerButton: {
    borderColor: palette.apple,

    '&:hover': {
      borderColor: palette.swampGreen,
    }
  },

  footerButtonPrimary: {
    background: palette.apple,
    borderColor: palette.apple,
    color: palette.white,

    '&:hover, &:focus': {
      background: palette.swampGreen,
      borderColor: palette.swampGreen,
      color: palette.mindShaft
    },
    '&.disabled': {
      background: palette.horizon,
      color: palette.white,
    }
  },
  footerButtonSecondary: {
    background: palette.white,
    borderColor: palette.white,
    color: palette.mindShaft,

    '&:hover, &:focus': {
      background: palette.silverChalice,
      borderColor: palette.silverChalice,
      color: palette.mindShaft
    },
    '&.disabled': {
      background: palette.white,
      color: palette.horizon
    }
  }
};