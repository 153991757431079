export default {
    // Blues
    'blueLagoon':                                  '#00778B',
    'toryBlue':                                    '#14509B',

    // Greens
    'apple':                                       '#648C3C',
    'swampGreen':                                  '#99B380',
    'horizon':                                     '#6484AD',

    // Yellows

    // Oranges
    'treePoppy':                                   '#F9A11A',

    // Reds
    'pink':                                        '#fecccd',
    'sangria':                                     '#97030a',
    
    // Grays
    'white':                                       '#FFFFFF',
    'midGray':                                     '#646569',
    'mindShaft':                                   '#222222',
    'silverChalice':                               '#ABABAB',
};