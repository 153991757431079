import palette from '../palette';

export default {
    fontFamily: 'Arial, Helvetica, sans-serif',

    primary: {
        backgroundColor: palette.toryBlue,
        borderColor: palette.toryBlue,
        focusColor: palette.toryBlue,
        hoverColor: palette.toryBlue,
        textColor: palette.white,
        disabledBackground: palette.horizon,
        disabledBorder: palette.horizon,
        disabledColor: palette.white,
        '&:hover': {
            backgroundColor: palette.toryBlue,
            borderColor: palette.toryBlue,
            color: palette.treePoppy,
        }
    },

    secondary: {
        backgroundColor: palette.white,
        borderColor: palette.midGray,
        focusColor: palette.silverChalice,
        hoverColor: palette.silverChalice,
        textColor: palette.toryBlue,
        disabledBackground: palette.white,
        disabledColor: palette.horizon,
    },

    tertiary: {
        textColor: palette.toryBlue,
        '&:hover': {
            color: palette.toryBlue,
        },
        '&.disabled': {
            color: palette.horizon
        }
    },

    extraSmall: {
        borderRadius: 4
    },

    normal: {
        borderRadius: 4
    }
};